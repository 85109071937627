import React from 'react'

const SvgAdFeature2 = props => (
  <svg id='ad_feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.ad_feature2_svg__st2{fill:#334966}'}</style>
    <g id='ad_feature2_svg__\u575A\u56FA\u7A33\u5B9A-48x48'>
      <circle id='ad_feature2_svg__Oval-7_4_' cx={25} cy={24.87} r={23} fill='none' stroke='#334966' strokeWidth={2} />
      <path id='ad_feature2_svg__Rectangle-54' fill='#fd5c1f' d='M25 27.87l12 6-12 6-12-6z' />
      <g id='ad_feature2_svg__Group_5_' transform='translate(12 9)'>
        <path
          id='ad_feature2_svg__Rectangle-54_1_'
          className='ad_feature2_svg__st2'
          d='M13 12.87l12 6-12 6-12-6 12-6zm0 2.23l-7.53 3.76L13 22.63l7.53-3.76L13 15.1z'
        />
        <path
          id='ad_feature2_svg__Combined-Shape_21_'
          className='ad_feature2_svg__st2'
          d='M12 22.13V5.97L3.92 18.09 12 22.13zm2-16.16v16.16l8.08-4.04L14 5.97zM1 18.87l12-18 12 18-12 6-12-6z'
        />
      </g>
    </g>
  </svg>
)

export default SvgAdFeature2
